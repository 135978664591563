import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            isWinChange: false
        };
    },
    mutations: {
        setIsWinChange(state, value) {
            state.isWinChange = value;
        }
    },
    actions: {
        updateIsWinChange({ commit }, value) {
            commit('isWinChange', value);
        }
    },
    getters: {
        isWinChange: (state) => state.isWinChange
    }
});

export default store;
