import { createRouter, createWebHistory } from 'vue-router';
import Home from '@/views/Home/home.vue';
import About from '@/views/About/about.vue';
import News from '@/views/News/news.vue';
import Products from '@/views/Products/products.vue';
import OM from '@/views/OM/om.vue';
import Cases from '@/views/Cases/cases.vue';
import Contact from '@/views/Contact/contact.vue';
import Videos from '@/views/Videos/videos.vue';
import Downloads from '@/views/Downloads/downloads.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/news',
    name: 'news',
    component: News,
  },
  {
    path: '/products',
    name: 'products',
    component: Products,
  },
  {
    path: '/om',
    name: 'om',
    component: OM,
  },
  {
    path: '/cases',
    name: 'cases',
    component: Cases,
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
  },
  {
    path: '/videos',
    name: 'videos',
    component: Videos,
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: Downloads,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.afterEach(() => {
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;
});

export default router;
