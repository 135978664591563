<template>
  <div ref="homeRef" class="home">
    <el-row>
      <el-col v-if="isMobile">
        <!--手机端轮播-->
        <div class="banner">
          <div class="item">
            <div class="img" :style="{ backgroundImage: `url(${about.mbannerImg})` }">
              <div class="con">
                <div class="txt animate__animated animate__bounceInUp">
                  <h3>{{$t('message.home.mainH1')}}<br />{{$t('message.home.mainH2')}}</h3>
                  <p>{{$t('message.home.mainP1')}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col v-else>
        <!--首页视频-->
        <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel" data-interval="10000" data-pause="false">
          <ol class="carousel-indicators">
            <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
          </ol>
          <div class="carousel-inner">
            <div v-for="(item, index) in carouselItems" :key="index" :class="['carousel-item', item.active ? 'active' : '']">
              <video class="d-block w-100" autoplay loop muted>
                <source :src="item.src" type="video/mp4">
                Your browser does not support the video tag.
              </video>
              <div class="carousel-caption d-none d-md-block">
                <h5>{{ item.h5 }}</h5>
                <p>{{ item.p }}</p>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-target="#carouselExampleCaptions" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-target="#carouselExampleCaptions" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </button>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="row-index">
          <div class="hd">
            <div class="wrap1500">
              <div class="t1">
                <h3>{{$t('message.home.about')}}</h3>
                <div class="line"></div>
              </div>
              <div class="con">
                <p>
                  {{$t('message.home.aboutP1')}}
                  <br />
                  {{$t('message.home.aboutP2')}}
                </p>
              </div>
            </div>
          </div>
          <div class="bd" :style="{ backgroundImage: `url(${about.companyImg})` }">
            <div class="wrap1500">
              <ul class="list-solo">
                <li>
                  <div class="pad">
                    <div class="ico"><i class="iconfont icon-liuchengshuoming"></i></div>
                    <div class="txt">
                      <p>{{$t('message.home.omP1')}}</p>
                      <p>{{$t('message.home.omP2')}}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="pad">
                    <div class="ico"><i class="iconfont icon-guanli"></i></div>
                    <div class="txt">
                      <p>{{$t('message.home.omP3')}}</p>
                      <p>{{$t('message.home.omP4')}}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="pad">
                    <div class="ico"><i class="iconfont icon-chanchuxiaoshuai"></i></div>
                    <div class="txt">
                      <p>{{$t('message.home.omP5')}}</p>
                      <p>{{$t('message.home.omP6')}}</p>
                      <p>{{$t('message.home.omP7')}}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="pad">
                    <div class="ico"><i class="iconfont icon-zhinengchanpin"></i></div>
                    <div class="txt">
                      <p>{{$t('message.home.omP8')}}</p>
                      <p>{{$t('message.home.omP9')}}</p>
                      <p>{{$t('message.home.omP10')}}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="pad">
                    <div class="ico"><i class="iconfont icon-trophy"></i></div>
                    <div class="txt">
                      <p>{{$t('message.home.omP11')}}</p>
                      <p>{{$t('message.home.omP12')}}</p>
                      <p>{{$t('message.home.omP13')}}</p>
                    </div>
                  </div>
                </li>
                <li class="li-more"><div class="pad"><router-link to="/about"><button class="btn btn-danger">{{$t('message.home.more')}}</button></router-link></div></li>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div class="row-index">
          <div class="hd">
            <div class="wrap1500">
              <div class="t1">
                <h3>{{$t('message.home.casesH1')}}</h3>
                <div class="line"></div>
                <p>{{$t('message.home.casesP1')}}</p>
              </div>
            </div>
          </div>
          <div class="cases" :style="{ backgroundImage: `url(${cases.bgImg})` }">
            <div class="wrap1500">
              <div class="content"><ImgCarousel :items="imgCarouselItems"/></div>
            </div>
            <div class="more">
              <router-link to="/cases"><button class="btn btn-outline-danger">{{$t('message.home.casesBtn')}}</button></router-link>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin: 40px auto;">
      <el-col :span="isMobile ? 24 : 12">
        <div class="row-index row-height">
          <div class="hd" :style="{ backgroundImage: `url(${product.bgImg})` }">
            <div class="wrap1500">
              <div class="t1">
                <h3>{{$t('message.home.omH1')}}</h3>
                <div class="line"></div>
                <h4>{{$t('message.home.omH2')}}</h4>
                <h6>{{$t('message.home.omH3')}}</h6>
              </div>
              <div class="more">
                <router-link to="/products"><button class="btn btn-danger">{{$t('message.home.more')}}</button></router-link>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="isMobile ? 24 : 12">
        <div class="row-index row-height">
          <div class="hd" :style="{ backgroundImage: `url(${om.bgImg})` }">
            <div class="wrap1500">
              <div class="t1">
                <h3>{{$t('message.home.productsH1')}}</h3>
                <div class="line"></div>
                <h4>{{$t('message.home.productsH2')}}</h4>
                <h6>{{$t('message.home.productsH3')}}</h6>
              </div>
              <div class="more">
                <router-link to="/om"><button class="btn btn-danger">{{$t('message.home.more')}}</button></router-link>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import $ from 'jquery';
import {mapState} from "vuex";
import 'animate.css';
import ImgCarousel from "@/components/ImgCarousel/imgcarousel.vue";

export default {
  name: "RadiantHome",
  components: {
    ImgCarousel
  },
  computed: {
    ...mapState(['isWinChange']),
    carouselItems() {
      return [
        { src: 'https://pvcleaning.radiantpv.com/video/home/av-rdn71.mp4', h5: this.$t('message.home.bannerH1'), p: this.$t('message.home.bannerP1'), active: true },
        { src: 'https://pvcleaning.radiantpv.com/video/home/av-rdn12.mp4', h5: this.$t('message.home.bannerH2'), p: this.$t('message.home.bannerP2') },
        { src: 'https://pvcleaning.radiantpv.com/video/home/av-rdn31.mp4', h5: this.$t('message.home.bannerH3'), p: this.$t('message.home.bannerP3') }
      ]
    }
  },
  data() {
    return {
      isMobile: false,
      about: {
        mbannerImg: 'https://pvcleaning.radiantpv.com/image/home/m_banner.jpg',
        companyImg: 'https://pvcleaning.radiantpv.com/image/home/company.png',
      },
      cases: {
        bgImg: 'https://pvcleaning.radiantpv.com/image/home/map.jpg',
      },
      product: {
        bgImg: 'https://pvcleaning.radiantpv.com/image/home/product.jpg',
      },
      om: {
        bgImg: 'https://pvcleaning.radiantpv.com/image/home/om.jpg',
      },
      imgCarouselItems: [
        { caseImg: 'https://pvcleaning.radiantpv.com/image/home/case_1.png', numImg: 'https://pvcleaning.radiantpv.com/image/home/case_num.png' },
        { caseImg: 'https://pvcleaning.radiantpv.com/image/home/case_2.png', numImg: 'https://pvcleaning.radiantpv.com/image/home/case_num.png' },
        { caseImg: 'https://pvcleaning.radiantpv.com/image/home/case_3.png', numImg: 'https://pvcleaning.radiantpv.com/image/home/case_num.png' },
        { caseImg: 'https://pvcleaning.radiantpv.com/image/home/case_4.png', numImg: 'https://pvcleaning.radiantpv.com/image/home/case_num.png' },
        { caseImg: 'https://pvcleaning.radiantpv.com/image/home/case_5.png', numImg: 'https://pvcleaning.radiantpv.com/image/home/case_num.png' },
        { caseImg: 'https://pvcleaning.radiantpv.com/image/home/case_6.png', numImg: 'https://pvcleaning.radiantpv.com/image/home/case_num.png' }
      ]
    };
  },
  watch:{
    isWinChange(n) {
      this.isMobile = n;
    }
  },
  methods: {},
  created() {
    this.isMobile = this.$store.state.isWinChange;
  },
  mounted() {
    $('#carouselExampleCaptions').carousel();
  },
  beforeUnmount() {}
};
</script>

<style lang="scss" scoped>
.carousel-item {
  height: 0;
  padding-top: 45.25%;
  background-color: #000;
}

.carousel-item img,
.carousel-item video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-caption {
  bottom: 20px;
}

.row-index .con {
  text-align: center;
}

.more {
  margin: 30px auto;
  text-align: center;
}

.banner,
.banner .img,
.banner .item {
  height: 100vh;
}

.banner .img {
  padding-top: 42%;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .con {
  position: absolute;
  top: 50%;
  margin-top: 50px;
  width: 100%;
  color: #fff;
  text-align: center;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.banner .con h3 {
  margin-bottom: .5em;
  color: #fff;
  font-size: 48px;
  line-height: 1.2;
}

.banner .txt {
  margin-bottom: 5%;
  padding: 0 2%;
}

.row-index .hd {
  padding: 3% 0;
  background-color: #fff;
}

.row-index .bd {
  background-color: #f3f3f3;
  background-image: url(@/assets/radiant.png);
  background-position: 50% bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.row-height {
  height: 600px;
}

.row-height .t1 h3 {
  color: white;
}

.row-height .t1 {
  color: white;
}

.row-height .hd {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.t1 {
  margin-bottom: 5px;
  padding-right: 2%;
  padding-left: 2%;
  text-align: center;
}

.t1 h3 {
  color: #4a4a4a;
  font-size: 48px;
  line-height: 1.3em;
}

.t1 .line {
  display: inline-block;
  margin: 4px 0;
  width: 50px;
  height: 2px;
  background-color: #e0e0e0;
}

.list-solo {
  overflow: hidden;
  margin: 0 -3%;
  width: 60%;
}

.list-solo li {
  float: left;
  padding: 2% 3%;
  width: 50%;
}

.list-solo li .pad {
  position: relative;
  padding: 10%;
  border-bottom: 2px solid #eb181f;
  border-top-left-radius: 50px;
  background-color: #fff;
  color: #323232;
}

.list-solo li .pad:before {
  display: inline-block;
  margin-right: -.25em;
  height: 100%;
  content: '';
  vertical-align: middle;
}

.list-solo li .iconfont {
  color: #ea1c30;
  font-size: 70px;
}

.list-solo li .ico {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  padding-right: inherit;
  width: 35%;
  text-align: right;
  line-height: 1;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
}

.list-solo li .txt {
  display: inline-block;
  width: 65%;
  vertical-align: middle;
  font-size: 18px;
}

.list-solo .li-more {
  text-align: center;
}

.list-solo .li-more .pad {
  border: none;
  background: 0 0;
}

@media screen and (max-width:1080px) {
  .list-solo {
    margin: 0 auto;
    width: 80%;
  }

  .list-solo li {
    width: 100%;
  }
}

@media screen and (max-width:768px) {
  .banner .con h3 {
    font-size: 24px;
  }

  .banner .con p {
    font-size: 14px;
  }

  .row-index h3 {
    font-size: 16px;
  }

  .row-index p {
    font-size: 12px;
  }

  .row-index .con p {
    font-size: 12px;
  }

  .t1 .line {
    width: 30px;
  }

  .list-solo li .pad {
    padding: 2%;
  }

  .list-solo li .ico {
    right: 20px;
  }

  .list-solo li .iconfont {
    font-size: 40px;
    margin-left: -100px;
  }

  .list-solo li .txt {
    font-size: 12px;
    margin-top: 10px;
    margin-left: 30px;
  }
}
</style>
