<template>
  <div ref="videosRef" class="videos">
    <el-row class="videos-main">
      <el-col>
        <div class="jumbotron" :style="{ backgroundImage: `url(${videos.bg1Img})` }">
          <div class="txt animate__animated animate__bounceInUp">
            <h1 class="display-4"></h1>
            <p class="lead"></p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="rdn-row">
      <el-col>

      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "RadiantVideos",
  components: {},
  computed: {},
  data() {
    return {
      videos: {
        bg1Img: require('@/assets/image/videos/bg.png')
      }
    };
  },
  methods: {

  }
};
</script>

<style scoped>
.jumbotron {
  color: white;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin: 0;
}

.jumbotron .txt {
  margin: 250px auto;
}

.rdn-row {
  padding: 100px 0;
  width: 80%;
  margin: 0 auto;
}

.rdn-row .col {
  padding-bottom: 20px;
  text-align: center;
}
</style>