<template>
  <div ref="contactRef" class="contact">
    <el-row class="contact-main">
      <el-col>
        <div class="jumbotron" :style="{ backgroundImage: `url(${contact.bg1Img})` }">
          <div class="txt animate__animated animate__bounceInUp">
            <h1 class="display-4">{{$t('message.contact.mainT')}}</h1>
            <p class="lead">{{$t('message.contact.mainP1')}}</p>
            <p class="lead">{{$t('message.contact.mainP2')}}</p>
            <p class="lead">{{$t('message.contact.mainP3')}}</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="rdn-row">
      <el-col :span="24">
        <el-card v-for="(item, index) in companys" :key="index" :style="{ height: '1000px', backgroundImage: `url(${item.cardImg})` }">
          <div class="c-header" :style="{ background: `rgba(240,30,39,${item.op}) url(${item.hdImg}) 50% bottom`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'contain' }">
            <div class="text">
              <h4>{{ item.name1 }}</h4>
              <p>{{ item.addr1 }}</p>
            </div>
            <div>
              <ul class="list-info">
                <li v-if="item.tel">
                  <h5><i class="iconfont icon-dianhua"></i> {{ item.tel1 }}</h5>
                  <p><a :href="`tel:${item.tel2}`">{{ item.tel2 }}</a></p>
                </li>
                <li v-if="item.fax">
                  <h5><i class="iconfont icon-fax"></i> {{ item.fax1 }}</h5>
                  <p>{{ item.fax2 }}</p>
                </li>
                <li v-if="item.eml">
                  <h5><i class="iconfont icon-email"></i> {{ item.eml1 }}</h5>
                  <p><a :href="`mailto:${item.eml2}`">{{ item.eml2 }}</a></p>
                </li>
                <li v-if="item.wz">
                  <h5><i class="iconfont icon-wangluo"></i> {{ item.wz1 }}</h5>
                  <p><a :href="`http://${item.wz2}`" target="_blank">{{ item.wz2 }}</a></p>
                </li>
                <li v-if="item.xs">
                  <h5><i class="iconfont icon-wangluo"></i> {{ item.xs1 }}</h5>
                  <p><a :href="`tel:${item.xs2}`" target="_blank">{{ item.xs2 }}</a></p>
                  <p><a :href="`tel:${item.xs3}`" target="_blank">{{ item.xs3 }}</a></p>
                </li>
              </ul>
            </div>
            <div class="map" v-if="item.go">
              <div class="img">
                <img src="@/assets/image/contact/map.gif">
              </div>
              <div class="txt">
                <a href="https://www.amap.com/search?id=B0FFFZDU34&amp;city=320506&amp;geoobj=120.427324%7C31.193711%7C120.921709%7C31.410789&amp;query_type=IDQ&amp;query=%E7%91%9E%E5%BE%97%E6%81%A9%E9%9B%86%E5%9B%A2&amp;zoom=12" target="_blank">
                  <span><i class="iconfont icon-ditu"></i> {{ item.go1 }}</span>
                </a>
              </div>
            </div>
          </div>
          <div class="c-footer">
            <h1>{{ item.f1 }}</h1>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "RadiantContact",
  components: {},
  computed: {
    companys() {
      return [
        {
          cardImg: 'https://pvcleaning.radiantpv.com/image/contact/card_cn.jpg',
          hdImg: 'https://pvcleaning.radiantpv.com/image/contact/card_header.png',
          op: 0.8,
          name1: this.$t('message.contact.companyCNH'),
          addr1: this.$t('message.contact.companyCNAddr'),
          tel1: this.$t('message.contact.companyCNTel1'),
          tel2: this.$t('message.contact.companyCNTel2'),
          fax1: this.$t('message.contact.companyCNFax1'),
          fax2: this.$t('message.contact.companyCNFax2'),
          eml1: this.$t('message.contact.companyCNEml1'),
          eml2: "sales@radiantpv.com",
          wz1: this.$t('message.contact.companyCNWz1'),
          wz2: this.$t('message.contact.companyCNWz2'),
          xs1: this.$t('message.contact.companyCNXs1'),
          xs2: this.$t('message.contact.companyCNXs2'),
          xs3: this.$t('message.contact.companyCNXs3'),
          go1: this.$t('message.contact.companyCNGo'),
          f1: this.$t('message.contact.companyCNF'),
          tel: true,
          fax: true,
          eml: true,
          wz: true,
          xs: true,
          go: true
        },
        {
          cardImg: 'https://pvcleaning.radiantpv.com/image/contact/card_ja.png',
          //cardImg: 'https://pvcleaning.radiantpv.com/image/contact/card_ja.jpg',
          hdImg: 'https://pvcleaning.radiantpv.com/image/contact/card_header.png',
          op: 0,
          name1: this.$t('message.contact.companyJAH'),
          addr1: this.$t('message.contact.companyJAAddr'),
          tel1: this.$t('message.contact.companyJATel1'),
          tel2: this.$t('message.contact.companyJATel2'),
          fax1: this.$t('message.contact.companyJAFax1'),
          fax2: this.$t('message.contact.companyJAFax2'),
          eml1: this.$t('message.contact.companyJAEml1'),
          eml2: "sales@radiant-pv.co.jp",
          wz1: this.$t('message.contact.companyJAWz1'),
          wz2: this.$t('message.contact.companyJAWz2'),
          xs1: this.$t('message.contact.companyJAXs1'),
          xs2: this.$t('message.contact.companyJAXs2'),
          xs3: this.$t('message.contact.companyJAXs3'),
          go1: this.$t('message.contact.companyJAGo'),
          f1: this.$t('message.contact.companyJAF'),
          tel: true,
          fax: true,
          eml: true,
          wz: true,
          xs: false,
          go: false
        },
        {
          cardImg: 'https://pvcleaning.radiantpv.com/image/contact/card_au.png',
          //cardImg: 'https://pvcleaning.radiantpv.com/image/contact/card_au.jpg',
          hdImg: 'https://pvcleaning.radiantpv.com/image/contact/card_header.png',
          op: 0,
          name1: this.$t('message.contact.companyAUH'),
          addr1: this.$t('message.contact.companyAUAddr'),
          tel1: this.$t('message.contact.companyAUTel1'),
          tel2: this.$t('message.contact.companyAUTel2'),
          fax1: this.$t('message.contact.companyAUFax1'),
          fax2: this.$t('message.contact.companyAUFax2'),
          eml1: this.$t('message.contact.companyAUEml1'),
          eml2: "sales@radiant-pv.com",
          wz1: this.$t('message.contact.companyAUWz1'),
          wz2: this.$t('message.contact.companyAUWz2'),
          xs1: this.$t('message.contact.companyAUXs1'),
          xs2: this.$t('message.contact.companyAUXs2'),
          xs3: this.$t('message.contact.companyAUXs3'),
          go1: this.$t('message.contact.companyAUGo'),
          f1: this.$t('message.contact.companyAUF'),
          tel: true,
          fax: false,
          eml: true,
          wz: true,
          xs: false,
          go: false
        },
      ]
    }
  },
  data() {
    return {
      contact: {
        bg1Img: 'https://pvcleaning.radiantpv.com/image/contact/bg.jpg',
      }
    };
  },
  methods: {}
};
</script>

<style scoped>
.contact {
  height: 100%;
}

.jumbotron {
  color: white;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 900px;
  margin: 0;
}

.jumbotron .txt {
  margin: 250px auto;
}

.rdn-row {
  width: 100%;
  height: 100%;
}

.map {
  margin: 10% auto;
}

.map img {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateZ(0) scale(1,1);
  width: 25%;
  vertical-align: bottom;
}

.map .txt {
  width: 25%;
  height: 70px;
  background-color: #666;
  color: #fff;
  text-align: center;
  font-size: 24px;
  line-height: 70px;
}

.map .txt a {
  color: #fff;
  text-decoration: none;
}

.map .txt .iconfont {
  font-size: 24px;
}

.el-card {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.c-header {
  width: 100%;
  height: 300px;
  border-radius: 80px 0px 80px 0px;
  color: #fff;
}

.c-header .text {
  position: relative;
  top: 30px;
  height: 100px;
  text-align: center;
}

.c-header .list-info {
  position: relative;
  top: 30px;
  text-align: center;
  line-height: 30px;
}

.c-header .list-info li {
  display: inline-table;
  width: 18%;
  text-align: left;
}

.c-header .list-info a {
  color: #fff;
  text-decoration: none;
}

.list-info li .iconfont {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  color: #d70011;
  text-align: center;
  line-height: 30px;
}

.el-card .c-footer {
  margin: 550px 0px 0px 50px;
  color: rgba(255,255,255,.7);
  font-weight: 700;
}

@media screen and (max-width:768px) {
  .display-4 {
    font-size: 24px;
  }

  .lead {
    font-size: 14px;
  }

  .map .txt {
    height: 22px;
    line-height: 20px;
    font-size: 12px;
  }

  .map .txt .iconfont {
    font-size: 12px;
  }

  .c-header {
    background: rgba(240,30,39,0) !important;
  }

  .c-header .text h4 {
    font-size: 18px;
  }

  .c-header .text p {
    font-size: 12px;
  }

  .c-header .list-info li {
    display: inline;
  }

  .list-info li .iconfont {
    display: inline;
  }

  .c-header .list-info h5, .c-header .list-info p {
   font-size: 12px;
  }

  .el-card .c-footer h1 {
    font-size: 18px;
  }
}
</style>