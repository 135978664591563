<template>
  <Header/>
  <router-view />
  <Footer/>
</template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  name: "RadiantAPP",
  components: {
    Header,
    Footer
  },
  created() {
    this.updateWinWidth();
  },
  mounted() {
    window.addEventListener('resize', this.updateWinWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateWinWidth);
  },
  methods: {
    updateWinWidth() {
      // 使用 requestAnimationFrame 提高性能
      requestAnimationFrame(() => {
        const winWidth = window.innerWidth;
        this.$store.state.isWinChange = winWidth < 1080;
      });
    }
  }
}
</script>

<style scoped>
@import url('@/assets/styles/global.css');
</style>