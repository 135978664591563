export default {
    header: {
        home: "首页",
        about: "关于我们",
        news: "最新资讯",
        products: "产品服务",
        om: "运维服务",
        cases: "项目案例",
        contact: "联系我们"
    },
	home: {
		bannerH1: "光伏电站智能轨道式干扫清洁机器人",
		bannerH2: "光伏电站智能清洁机器人",
		bannerH3: "光伏电站高效清洁手持清洁机",
		bannerP1: "智能运维服务/运维机器人/无人值守",
		bannerP2: "智能运维服务/运维机器人/无人值守",
		bannerP3: "智能运维服务/运维机器人/无人值守",
		mainH1: "光伏电站一站式全天候",
		mainH2: "智能运维服务商",
		mainP1: "智能运维服务/运维机器人/光伏安装系统/工业自动化",
		about: "关于我们",
		aboutP1: "瑞得恩集团坐落于中国苏州，是一家集光伏电站智能运维、太阳能光伏发电、光伏应用产品、光伏配件、光伏仪器、金属制造、精密加工为一体的综合性实业集团公司。",
		aboutP2: "集团以诚信、创新、乐业、平和、共赢为核心价值， 以领先业界的技术水准，为客户提供最有价值的产品及技术支持服务。",
		omH1: "运维服务",
		omH2: "光伏电站一站式全天候智能运维服务商",
		omH3: "智能运维服务/光伏安装系统/工业自动化",
		omP1: "全天候智能运维",
		omP2: "管理服务",
		omP3: "为用户提供全方位的",
		omP4: "定制化运维服务方案",
		omP5: "高度清洁阵列污渍，",
		omP6: "显著提升发电效益，",
		omP7: "为用户持续创造价值",
		omP8: "光伏阵列全境自动清扫，",
		omP9: "智能调度、自动部署，",
		omP10: "高效作业，降低成本",
		omP11: "自主知识产权、",
		omP12: "自有专利技术",
		omP13: "约120余项",
		casesH1: "项目案例",
		casesP1: "为客户提供安全可靠、技术领先的增值服务和解决方案",
		casesBtn: "查看更多案例",
		productsH1: "产品服务",
		productsH2: "光伏电站智能清扫机器人",
		productsH3: "轨道清扫机器人/干湿清扫机器人/遥控清扫机器人/手持清扫工具",
		more: "查看详情",
	},
	footer: {
        about: "关于我们",
        intro: "公司简介",
        course: "发展历程",
		honor: "荣誉资质",
        maintenance: "运维服务",
        smart: "智能运维",
        install: "光伏安装系统",
        industry: "工业自动化",
        service: "产品服务",
        download: "下载中心",
		video: "视频中心",
		products: "产品推荐",
		rdn12: "智能清扫机器人",
		rdn21: "智能干扫机器人",
		rdn22: "遥控清扫机器人",
		rdn31: "手持清扫工具",
		rdn51: "智能观测站",
		rdn71: "智能轨道清扫机器人",
        sweep: "扫一扫",
        shop: "关注我们 线上商城",
        contact: "联系我们",
        address: "地址：江苏省苏州市胥口镇子胥路636号",
        copyright: "Copyright © 2019 radiantpv.com 苏州瑞得恩光能科技有限公司版权所有;部分信息来自互联网,如有侵权,请告知! ",
        record: "苏ICP备14042181号-1"
    },
	about: {
		mainT: "瑞得恩",
		mainP1: "光伏电站一站式全天候智能运维服务商",
		profileH1: "ABOUT RADIANT",
		profileH2: "关于我们",
		profileS1: "瑞得恩集团坐落于中国苏州，是一家集光伏电站智能运维、太阳能光伏发电、光伏应用产品、光伏配件、光伏仪器、金属制造、精密加工为一体的综合性实业集团公司。集团以诚信、创新、乐业、平和、共赢为核心价值，以领先业界的技术水准，为客户提供最有价值的产品及技术支持服务。",
		profileS2: "公司现有研发、技术方案、项目实施专职技术人员70余人，其中研发人员20人，高级工程师2人，博士/硕士1人，外部合作博士以上人才5人，荣获国家级高新技术企业荣誉称号，并建有“江苏省全天候智能运维机器人系统工程技术研究中心”。",
		profileS3: "公司拥有先进的光伏电站智能运维云平台，通过大数据中心对光伏电站、智能机器人进行集中管理、控制及调度，实现电站现场少人甚至无人值守的智能化运维环境。",
		profileS4: "瑞得恩人坚持以客户需求为中心和满足客户价值主张为己任，为客户提供安全可靠、技术领先的增值服务和解决方案。坚决落实质量效益型和创新驱动型发展要求，改造提升传统产业、攻坚发展高新产业，加快转型升级，发扬工匠精神，在民族工业复兴的长征路上，瑞得恩将继续奋勇前行，努力成为光伏电站一站式全天候智能运维服务商。",
		historyH1: "COMPANY HISTORY",
		historyH2: "公司历程",
		historyC1: "成立苏州创捷自动化仪表实业有限公司",
		historyC2: "成立苏州瑞得恩光能科技有限公司，进入光伏市场",
		historyC3: "成立苏州瑞得恩光能科技有限公司分店Radiant Japan",
		historyC4: "首次参加日本PV System EXPO展会",
		historyC5: "成立Radiant Japan 株式会社",
		historyC6: "成立苏州瑞得恩光能科技有限公司分公司Radiant Australia",
		historyC7: "开始自主投入研发组件清洗机器",
		historyC8: "瑞得恩组件清洗机器人首次亮相日本PV SYSTEM EXPO展会",
		historyC9: "着手创立瑞得恩太阳能发电站智能管理系统",
		historyC10: "参加SNEC第十六届(2022)国际太阳能光伏与智慧能源(上海)大会暨展览会",
		historyC11: "参加越南太阳能光伏及电池储能展览会 The Solar Show",
		historyC12: "参加日本国际光伏博览会丨东京光伏展览会",
		historyC13: "参加中东能源展 | 沙特能源展",
		historyC14: "参加日本大阪太阳能光伏展览会 PV EXPO OSAKA",
		historyC15: "参加德国慕尼黑光伏太阳能展览会 Intersolar Europe 2024",
		honorH1: "HONOR",
		honorH2: "荣誉资质"
	},
	news: {
		mainS1: "公司新闻",
		more: "查看详情",
		play: "播放视频",
		slidersV1: "坚持与传承瑞得恩科创精神，坚持数智化",
		slidersV2: "日本国际光伏博览会丨东京光伏展览会"
	},
	products: {
		mainH1: "产品中心",
		mainP1: "智能轨道清扫机器人/智能干湿两用清扫机器人/智能接驳车/手持清扫工具",
		advantage: "产品优势",
		rdn71: "智能轨道清扫机器人",
		rdn71b11: "自供电,全天候运维",
		rdn71b12: "分体式模块化设计",
		rdn71b13: "露尘设计,拒绝沉积",
		rdn71b14: "超强清洁能力",
		rdn71b15: "大容量电池",
		rdn71b16: "智能识别技术",
		rdn71b21: "自身光伏板为设备自给的充电控制技术,24小时不断电运维。",
		rdn71b22: "上下部分形成独立模块,分体式驱动与限位设计,方便运输与安装,柔性链接,提高设备适应力。",
		rdn71b23: "采用必要的露尘设计,让灰尘无法在机器腔体内沉积,保障机械结构的稳定性。",
		rdn71b24: "具备良好灰尘清洁效果,比普通清洁提高了8%,可最大提升26%发电量。",
		rdn71b25: "33AH大容量耐高低温电池,最大续航量可达2公里。",
		rdn71b26: "智能识别面板、桥架和基站,自主定位与自动纠偏。",
		rdn22: "遥控清扫机器人",
		rdn22b11: "面板清洁效率高",
		rdn22b12: "面板清洁度高",
		rdn22b13: "自身轻巧、无损面板",
		rdn22b14: "模块化设计",
		rdn22b15: "",
		rdn22b16: "",
		rdn22b21: "2人操作,单台机器人一天最高清扫1.5MW。",
		rdn22b22: "大水量冲洗,面板清洁度达到100%。",
		rdn22b23: "通过自身重量的深度优化和轻量化结构设计,使其重量控制在34Kg,通过第三方测试长期运行对面板无损伤。",
		rdn22b24: "设备模块化设计,机身、清扫机头和电池均可快速拆卸安装,便于设备的运输携带及维保。",
		rdn22b25: "",
		rdn22b26: "",
		rdn12: "智能清扫机器人",
		rdn12b11: "阵列清扫路径规划",
		rdn12b12: "智能防滑、防跌落技术",
		rdn12b13: "面板清洁率高",
		rdn12b14: "自身轻巧、无损面板",
		rdn12b15: "模块化设计",
		rdn12b16: "夜视功能",
		rdn12b21: "机器人自动识别阵列类型及组件大小,并自主规划清扫路径,保障清扫效果和运行",
		rdn12b22: "通过结构、传感器和智能控制的联合设计,保证在光滑斜面及阵列边缘稳定行走，长期测试无滑落风险",
		rdn12b23: "通过智能浮动清扫技术和轨迹优化设计,对组件面板表面灰尘分层清扫,保证面板清扫全覆盖，清洁率超过98%",
		rdn12b24: "结构深度优化和轻量化设计,净重控制在28kg以内,通过第三方测试长期运行对面板无损伤",
		rdn12b25: "机身、清扫机头、电池和水箱均可快速拆卸安装,便于设备的运输及携带",
		rdn12b26: "通过优化照明系统和视觉系统,机器人可在夜间作业,大大提高了作业效率",
		rdn21: "智能清扫机器人",
		rdn21b11: "自主环境识功能",
		rdn21b12: "自动规划清扫路径",
		rdn21b13: "智能防滑、防跌落技术",
		rdn21b14: "自身轻巧、无损面板",
		rdn21b15: "干扫清洁,无需水源",
		rdn21b16: "模块化设计",
		rdn21b21: "基于Vslam技术自动建图,自动识别缝隙与面板。",
		rdn21b22: "自动规划,根据自建图,自动规划最优清扫路径,100%覆盖无遗漏。",
		rdn21b23: "通过智能浮动清扫技术和轨迹优化设计,对组件面板表面灰尘分层清扫,保证面板清扫全覆盖，清洁率超过98%。",
		rdn21b24: "通过自身重量的深度优化和轻量化结构设计,其重量控制在36Kg之内,通过第三方测试长期运行对面板无损伤。",
		rdn21b25: "机器干扫清洁,无需水源,可适用于缺水地区。",
		rdn21b26: "设备模块化设计,机身、清扫机头和电池均可快速拆卸安装,便于设备的运输及携带。",
		rdn51: "智能观测站",
		rdn51b11: "实时上传监测数据",
		rdn51b12: "自供电独立电源",
		rdn51b13: "智能IV检测",
		rdn51b14: "精准分析气象数据",
		rdn51b15: "",
		rdn51b16: "",
		rdn51b21: "系统通过无线通讯,监测数据实时上传。",
		rdn51b22: "系统配置光伏离网储能,自供电独立电源,保证24小时在线监测。",
		rdn51b23: "系统通过智能IV监测，对光伏阵列发电状态进行全天候跟踪,并自动绘制发电功率曲线，通过大数据分析,为客户提供光伏发电功率降低告警及维护提醒。",
		rdn51b24: "配置气象观测站,对每日气象数据,温度、湿度、风向、风速、辐照等作出精准分析。",
		rdn51b25: "",
		rdn51b26: "",
		rdn31: "手持清扫工具",
		rdn31b11: "清扫机头运行平稳",
		rdn31b12: "模块化设计",
		rdn31b13: "轻量化设计",
		rdn31b14: "重污染带水清扫",
		rdn31b15: "清扫机头脱困功能",
		rdn31b16: "夜视功能",
		rdn31b21: "隐藏式电机驱动设计,体积小;重心平稳,清扫无跳动、无共振;无刷电机,经久耐用。",
		rdn31b22: "手柄、电池包、延长杆、机头均可独立拆卸安装,便于包装运输及产品功能拓展。",
		rdn31b23: "产品重量仅8kg,便于操作,必要时电池包放进电源背包,操作手感更理想。",
		rdn31b24: "产品配合中性清洁剂,进行重污染环境的带水清扫,清洁油污、顽固污渍效果理想。",
		rdn31b25: "误操作,机头冲出边界时,可利用脱困按钮,返回清扫平面。",
		rdn31b26: "产品具有夜视示廓灯,便于在夜间清扫作业。",
	},
	om: {
		mainH1: "运维服务",
		mainP1: "绿色能量 智慧运维",
		c1S1: "电站除灰",
		c1P1: "智能清除组件表面灰尘、鸟粪、腐烂树叶、顽固物质；重点清除组件下沿的积灰带，保持光伏组件最佳的发电状态。",
		c2S1: "电气及设备巡检服务",
		c2P1: "利用无人机、EL检测、IV检测及热成像技术对光伏组件进行全方位检测，检套光伏组件隐裂、热斑、电池片损伤等组件故障，及时解决有效提升组件发电效率。",
		c2P11: "对逆变器、汇流箱等电气设备进行巡检排查，排除故障风险，保障光伏电站安全运行。",
		c2P12: "组件阴影遮挡、系统配置的合理性对光伏发电的影响很大，通过对优化计算，对配置方案进行升级，有效提高系统的发电效率。",
		c1S2: "除草服务",
		c1P2: "借助智能设备，在春秋季节清理杂草，能够有效防止茂盛的草木对组件的遮挡；秋冬季节严防枯草引发火灾。",
		c2S2: "除雪服务",
		c2P2: "借助设备和人工，在下雪之初，快速有效处理组件表面积雪，防止积雪冰冻，长时间无法清扫，带来发电量的严重损失。",
		c2P21: "",
		c2P22: ""
	},
	cases: {
		mainH1: "项目案例",
		mainP1: "高效能 低成本",
		play: "点击播放",
		rdn71: "智能轨道清扫机器人",
		rdn71S1: "新疆光伏电站清扫现场",
		rdn71S2: "宁夏光伏电站清扫现场",
		rdn22: "遥控清扫机器人",
		rdn22S1: "瑞得恩屋顶光伏电站清扫现场",
		rdn22S2: "屋顶光伏电站清扫现场",
		rdn2112: "智能清扫机器人",
		rdn2112S1: "南通屋顶光伏电站清扫现场",
		rdn2112S2: "瑞得恩屋顶光伏电站清扫现场",
		rdn51: "智能观测站",
		rdn51S1: "新疆光伏电站清扫现场",
		rdn31: "手持清扫工具",
		rdn31S1: "日本光伏电站清扫现场",
		rdn31S2: "昆山屋顶光伏电站清扫现场"
	},
    contact: {
		mainT: "联系我们",
		mainP1: "瑞得恩是一家集光伏电站智能运维、太阳能光伏发电、",
		mainP2: "光伏应用产品、光伏配件、光伏仪器、金属制造、",
		mainP3: "精密加工为一体的综合性实业集团公司",
		companyCNH: "苏州瑞得恩光能科技有限公司",
		companyCNAddr: "地址:中国苏州市胥口镇子胥路636号",
		companyCNTel1: "电话：",
		companyCNTel2: "86-512-66572916",
		companyCNFax1: "传真：",
		companyCNFax2: "86-512-66572756",
		companyCNEml1: "邮箱：",
		companyCNWz1: "网址：",
		companyCNWz2: "www.radiantpv.com",
		companyCNXs1: "销售热线：",
		companyCNXs2: "0512-66368219",	
		companyCNXs3: "0512-66316900",
		companyCNGo: "到这儿去",
		companyCNF: "Radiant China",
		companyJAH: "日本瑞得恩",
		companyJAAddr: "地址:〒150-0011 東京都渋谷区東三丁目15−5　AYビル4階",
		companyJATel1: "电话：",
		companyJATel2: "(03)6427-7173",
		companyJAFax1: "传真：",
		companyJAFax2: "(03)6427-7183",
		companyJAEml1: "邮箱：",
		companyJAWz1: "网址：",
		companyJAWz2: "www.radiantpv.com",
		companyJAXs1: "销售热线：",
		companyJAXs2: "0512-66368219",	
		companyJAXs3: "0512-66316900",
		companyJAGo: "到这儿去",
		companyJAF: "Radiant Japan",
		companyAUH: "澳洲瑞得恩",
		companyAUAddr: "地址:43 Porter Street, Hemmant QLD 4174.",
		companyAUTel1: "电话：",
		companyAUTel2: "07 3823 2096",
		companyAUFax1: "传真：",
		companyAUFax2: "86-512-66572756",
		companyAUEml1: "邮箱：",
		companyAUWz1: "网址：",
		companyAUWz2: "www.radiantpv.com",
		companyAUXs1: "销售热线：",
		companyAUXs2: "0512-66368219",	
		companyAUXs3: "0512-66316900",
		companyAUGo: "到这儿去",
		companyAUF: "Radiant Australia"
    }
}