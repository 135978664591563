import { createApp, ref, onMounted, onBeforeUnmount, nextTick } from 'vue';
import App from './App.vue';
import router from "@/router";
import store from '@/store';
import { i18n } from '@/i18n';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';

const app = createApp(App);

app.config.globalProperties.$i18n = i18n;
app.config.globalProperties.$ref = ref;
app.config.globalProperties.$onMounted = onMounted;
app.config.globalProperties.$onBeforeUnmount = onBeforeUnmount;
app.config.globalProperties.$nextTick = nextTick;

app.use(router)
    .use(store)
    .use(ElementPlus, { i18n: i18n.global.t })
    .use(i18n)
    .mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}