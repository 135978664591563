<template>
  <footer ref="footerRef" class="footer bg-dark text-light py-5">
    <!-- 中间 Logo 区域 -->
    <div class="footer-logo text-center mb-4">
      <span>Radiant · 瑞得恩</span>
      <p>诚信·创新·乐业·平和·共赢</p>
    </div>

    <!-- 横线 -->
    <div class="footer-separator border-bottom border-gray mb-4 mx-auto" style="max-width: 80%;"></div>

    <!-- 导航 -->
    <nav class="footer-navigation row justify-content-center">
      <!-- 关于我们板块 -->
      <div class="col-md col-6 mb-4">
        <div class="nav-category font-weight-light mb-2">{{$t('message.footer.about')}}</div>
        <a href="/about/#company_profile" class="d-block text-muted mb-2">{{$t('message.footer.intro')}}</a>
        <a href="/about/#company_history" class="d-block text-muted mb-2">{{$t('message.footer.course')}}</a>
        <a href="/about/#company_honor" class="d-block text-muted mb-2">{{$t('message.footer.honor')}}</a>
      </div>

      <!-- 运维服务板块 -->
      <div class="col-md col-6 mb-4">
        <div class="nav-category font-weight-light mb-2">{{$t('message.footer.maintenance')}}</div>
        <router-link to="/om" class="d-block text-muted mb-2">{{$t('message.footer.smart')}}</router-link>
        <a href="http://pvmounting.radiantpv.com/" target="_blank" class="d-block text-muted mb-2">{{$t('message.footer.install')}}</a>
        <a href="http://ai.radiantpv.com/" target="_blank" class="d-block text-muted mb-2">{{$t('message.footer.industry')}}</a>
      </div>

      <!-- 产品服务板块 -->
      <div class="col-md col-6 mb-4">
        <div class="nav-category font-weight-light mb-2">{{$t('message.footer.service')}}</div>
        <router-link to="/downloads" class="d-block text-muted mb-2">{{$t('message.footer.download')}}</router-link>
        <router-link to="/videos" class="d-block text-muted mb-2">{{$t('message.footer.video')}}</router-link>
      </div>

      <!-- 产品推荐板块 -->
      <div class="col-md col-6 mb-4">
        <div class="nav-category font-weight-light mb-2">{{$t('message.footer.products')}}</div>
        <a href="/products/#RDN71" class="d-block text-muted mb-2">{{$t('message.footer.rdn71')}}</a>
        <a href="/products/#RDN12" class="d-block text-muted mb-2">{{$t('message.footer.rdn12')}}</a>
        <a href="/products/#RDN21" class="d-block text-muted mb-2">{{$t('message.footer.rdn21')}}</a>
        <a href="/products/#RDN22" class="d-block text-muted mb-2">{{$t('message.footer.rdn22')}}</a>
        <a href="/products/#RDN31" class="d-block text-muted mb-2">{{$t('message.footer.rdn31')}}</a>
        <a href="/products/#RDN51" class="d-block text-muted mb-2">{{$t('message.footer.rdn51')}}</a>
      </div>
    </nav>

    <!-- 二维码 -->
    <div class="footer-qrcode d-flex justify-content-center align-items-center mt-4">
      <div class="douyin">
        <img src="@/assets/douyin-qrcode.png" style="max-width: 200px;">
      </div>
      <div class="text ml-3">
        <p class="mb-1">{{$t('message.footer.sweep')}}</p>
        <p class="mb-1">{{$t('message.footer.shop')}}</p>
      </div>
      <el-button class="contact-button bg-light text-black ml-auto" size="small" @click="handleToPages('contact')">{{$t('message.footer.contact')}}</el-button>
    </div>

    <!-- 横线 -->
    <div class="footer-separator border-bottom border-gray mt-4 mx-auto" style="max-width: 80%;"></div>

    <!-- 版权 -->
    <div class="footer-copyright text-center mt-4 mx-auto" style="max-width: 80%;">
      <p class="mb-2">{{$t('message.footer.address')}}</p>
      <p class="d-flex justify-content-center align-items-center mb-0">{{$t('message.footer.copyright')}}
        <img src="@/assets/record.png" width="20" height="auto" class="ml-1">{{$t('message.footer.record')}}</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'RadintFooter',
  methods: {
    handleToPages(pageName) {
      this.$router.push({ name: pageName});
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.footer-logo {
  height: 200px;
  margin-top: 30px;
}

.footer-logo span {
  line-height: 100px;
  font-size: 45px;
  font-weight: bold;
  font-family: system-ui;
}

.footer-logo p {
  font-size: 20px;
  font-family: monospace;
}

.footer a:hover {
  font-weight: bold;
}

.col-md {
  max-width: 18%;
}

.footer-qrcode {
  margin: auto 12%;
}

.douyin {
  width: 150px;

  img {
    width: 200px;
  }
}

@media screen and (max-width:768px) {
  .col-md {
    max-width: 20%;
    font-size: 12px;
  }

  .footer-qrcode {
    margin: auto 6%;
    font-size: 12px;

    .el-button {
      position: relative;
      left: -30px;
    }
  }

  .douyin {
    width: 90px;

    img {
      width: 130px;
    }
  }

  .footer-copyright {
    font-size: 12px;
  }
}
</style>