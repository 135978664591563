export default {
    header: {
        home: "Home",
        about: "About",
        news: "News",
        products: "Products",
        om: "O&M",
        cases: "Cases",
        contact: "Contact"
    },
    footer: {
        about: "About Us",
        intro: "Company Profile",
        course: "Development History",
		honor: "Honors & Qualifications",
        maintenance: "O&M Services",
        smart: "Smart O&M",
        install: "PV Installation System",
        industry: "Industrial Automation",
        service: "Products & Services",
        download: "Downloads",
		video: "Videos",
		products: "Product Recommendations",
		rdn12: "Smart Cleaning Robot",
		rdn21: "Smart Dry Cleaning Robot",
		rdn22: "Remote Control Cleaning Robot",
		rdn31: "Handheld Cleaning Tool",
		rdn51: "Smart Observation Station",
		rdn71: "Smart Track Cleaning Robot",
        sweep: "Scan",
        shop: "Follow Us Online Store",
        contact: "Contact Us",
        address: "Address: No. 636, Zixu Road, Xukou Town, Suzhou City, Jiangsu Province",
        copyright: "Copyright © 2019 radiantpv.com Radiant PV Technology Co., Ltd. All rights reserved. Some information comes from the Internet. If there is any infringement, please inform!",
        record: "Su ICP No. 14042181-1"
    },
	home: {
        bannerH1: "Smart Rail Cleaning Robot for Solar Power Plants",
        bannerH2: "Smart Cleaning Robot for Solar Power Plants",
        bannerH3: "High-Efficiency Handheld Cleaner for Solar Power Plants",
        bannerP1: "Smart O&M Services/O&M Robots/Unattended Operation",
        bannerP2: "Smart O&M Services/O&M Robots/Unattended Operation",
        bannerP3: "Smart O&M Services/O&M Robots/Unattended Operation",
        mainH1: "One-Stop All-Weather Service for Solar Power Plants",
        mainH2: "Smart O&M Service Provider",
        mainP1: "Smart O&M Services/O&M Robots/Solar Installation Systems/Industrial Automation",
        about: "About Us",
        aboutP1: "Ruideng Group, located in Suzhou, China, is a comprehensive industrial group integrating smart O&M for solar power plants, solar power generation, photovoltaic application products, accessories, instruments, metal manufacturing, and precision processing.",
        aboutP2: "With integrity, innovation, dedication, harmony, and win-win as core values, we provide the most valuable products and technical support services to our customers with industry-leading technology.",
        omH1: "O&M Services",
        omH2: "One-Stop All-Weather Smart O&M Service Provider for Solar Power Plants",
        omH3: "Smart O&M Services/Solar Installation Systems/Industrial Automation",
        omP1: "All-Weather Smart O&M",
        omP2: "Management Services",
        omP3: "Providing users with comprehensive",
        omP4: "customized O&M solutions",
        omP5: "Highly clean array stains,",
        omP6: "significantly improve power generation efficiency,",
        omP7: "continuously create value for users",
        omP8: "Fully automatic cleaning of photovoltaic arrays,",
		omP9: "smart scheduling, automatic deployment,",
        omP10: "efficient operation, cost reduction",
        omP11: "Independent intellectual property,",
        omP12: "proprietary patented technology",
        omP13: "about 120 items",
        casesH1: "Project Cases",
        casesP1: "Providing customers with safe, reliable, and technologically advanced value-added services and solutions",
        casesBtn: "View More Cases",
        productsH1: "Product Services",
        productsH2: "Smart Cleaning Robots for Solar Power Plants",
        productsH3: "Rail Cleaning Robots/Dry-Wet Cleaning Robots/Remote Control Cleaning Robots/Handheld Cleaning Tools",
        more: "View Details"
	},
	news: {
        mainS1: "Company News",
        more: "View Details",
        play: "Play Video",
        slidersV1: "Upholding and Advancing Ruideng's Innovation Spirit, Promoting Digitalization",
        slidersV2: "Japan International Photovoltaic Expo | Tokyo Exhibition"
	},
	about: {
		mainT: "Radiant",
		mainP1: "One-stop, All-weather Smart O&M Service Provider for PV Power Plants",
		profileH1: "ABOUT RADIANT",
		profileH2: "关于我们",
		profileS1: "Radiant Group, headquartered in Suzhou, China, is a comprehensive industrial group integrating smart O&M of PV power plants, solar photovoltaic power generation, PV application products, PV accessories, PV instruments, metal manufacturing, and precision processing. With integrity, innovation, job enjoyment, peace, and win-win as our core values, and based on industry-leading technical strength, we provide our customers with the most valuable products and technical support services.",
		profileS2: "Currently, the company has over 70 full-time technical personnel, including 20 R&D personnel, 2 senior engineers, 1 doctor/master, and 5 external doctoral talents. It has been awarded the title of National High-tech Enterprise and established the \"Jiangsu Engineering Technology Research Center for All-weather Intelligent Operation and Maintenance Robot System\".",
		profileS3: "The company has an advanced smart O&M cloud platform for PV power plants, which centrally manages, controls, and schedules PV power plants and intelligent robots through a big data center, realizing a smart O&M environment with few or even no personnel on site.",
		profileS4: "Radiant adheres to the principle of customer-centricity and is committed to meeting customer value propositions, providing customers with safe, reliable, technologically advanced value-added services and solutions. We resolutely implement the requirements of quality and efficiency-oriented and innovation-driven development, transform and upgrade traditional industries, vigorously develop high-tech industries, and accelerate transformation and upgrading. Carrying forward the spirit of craftsmanship, on the journey of national industrial rejuvenation, Radiant will continue to move forward bravely and strive to become a one-stop, all-weather intelligent operation and maintenance service provider for photovoltaic power plants.",
		historyH1: "COMPANY HISTORY",
		historyH2: "公司历程",
		historyC1: "Established Suzhou Chuangjie Automation Instrument Industrial Co., Ltd.",
		historyC2: "Established Suzhou Radiant PV Technology Co., Ltd. and entered the PV market",
		historyC3: "Established Radiant Japan, a branch of Suzhou Radiant PV Technology Co., Ltd.",
		historyC4: "First participation in PV System EXPO in Japan",
		historyC5: "Established Radiant Japan Co., Ltd.",
		historyC6: "Established Radiant Australia, a branch of Suzhou Radiant PV Technology Co., Ltd.",
		historyC7: "Started investing in the independent development of module cleaning robots",
		historyC8: "Radiant module cleaning robot debuted at PV SYSTEM EXPO in Japan",
		historyC9: "Started building the Radiant Solar Power Plant Smart Management System",
		historyC10: "Participated in the 16th (2022) SNEC International Solar Photovoltaic and Smart Energy (Shanghai) Conference & Exhibition",
		historyC11: "Participated in The Solar Show in Vietnam",
		historyC12: "Participated in the Japan International Photovoltaic Exhibition | Tokyo PV Exhibition",
		historyC13: "Participated in the Middle East Energy Exhibition | Saudi Arabia Energy Exhibition",
		historyC14: "Participated in PV EXPO OSAKA",
		historyC15: "Participated in Intersolar Europe 2024 in Munich, Germany",
		honorH1: "HONOR",
		honorH2: "荣誉资质"
	},
	products: {
		"mainH1": "Product Center",
		"mainP1": "Smart Rail Cleaning Robot/Smart Dry & Wet Cleaning Robot/Smart Shuttle/Handheld Cleaning Tool",
		"advantage": "Product Advantage",
		"rdn71": "Smart Rail Cleaning Robot",
		"rdn71b11": "Self-Powered, All-Weather Operation",
		"rdn71b12": "Modular Design",
		"rdn71b13": "Dust Exposure Design",
		"rdn71b14": "Strong Cleaning Ability",
		"rdn71b15": "High Capacity Battery",
		"rdn71b16": "Smart Recognition Technology",
		"rdn71b21": "Self-charging via its own solar panel, 24/7 operation.",
		"rdn71b22": "Split modular design for easy transport and installation.",
		"rdn71b23": "Dust exposure design to prevent dust accumulation.",
		"rdn71b24": "8% better cleaning efficiency, up to 26% increased power generation.",
		"rdn71b25": "33AH high-capacity battery, up to 2km endurance.",
		"rdn71b26": "Smart recognition of panels, bridges, and base stations.",
		"rdn22": "Remote Control Cleaning Robot",
		"rdn22b11": "High Panel Cleaning Efficiency",
		"rdn22b12": "High Panel Cleanliness",
		"rdn22b13": "Lightweight, Non-Damaging",
		"rdn22b14": "Modular Design",
		"rdn22b15": "",
		"rdn22b16": "",
		"rdn22b21": "Operated by 2 people, can clean up to 1.5MW per day.",
		"rdn22b22": "High water volume washing, 100% panel cleanliness.",
		"rdn22b23": "Optimized weight, non-damaging to panels.",
		"rdn22b24": "Modular design for easy transport and maintenance.",
		"rdn22b25": "",
		"rdn22b26": "",
		"rdn12": "Smart Cleaning Robot",
		"rdn12b11": "Array Cleaning Path Planning",
		"rdn12b12": "Smart Anti-Slip, Anti-Fall Technology",
		"rdn12b13": "High Cleaning Rate",
		"rdn12b14": "Lightweight, Non-Damaging",
		"rdn12b15": "Modular Design",
		"rdn12b16": "Night Vision Function",
		"rdn12b21": "Automatically recognizes array type and size, plans cleaning path.",
		"rdn12b22": "Ensures stable walking on smooth slopes and edges.",
		"rdn12b23": "98%+ cleaning rate.",
		"rdn12b24": "28kg, non-damaging.",
		"rdn12b25": "Easy assembly and disassembly.",
		"rdn12b26": "Night vision function for nighttime operations.",
		"rdn21": "Smart Cleaning Robot",
		"rdn21b11": "Self-Environment Recognition",
		"rdn21b12": "Auto Path Planning",
		"rdn21b13": "Smart Anti-Slip, Anti-Fall Technology",
		"rdn21b14": "Lightweight, Non-Damaging",
		"rdn21b15": "Dry Cleaning, No Water Needed",
		"rdn21b16": "Modular Design",
		"rdn21b21": "Automatic mapping using Vslam technology.",
		"rdn21b22": "Auto path planning for optimal coverage.",
		"rdn21b23": "98%+ cleaning rate.",
		"rdn21b24": "36kg, non-damaging.",
		"rdn21b25": "Dry cleaning, no water needed.",
		"rdn21b26": "Modular design for easy assembly.",
		"rdn51": "Smart Observation Station",
		"rdn51b11": "Real-Time Data Upload",
		"rdn51b12": "Self-Powered Independent Power Supply",
		"rdn51b13": "Smart IV Detection",
		"rdn51b14": "Precise Meteorological Data Analysis",
		"rdn51b15": "",
		"rdn51b16": "",
		"rdn51b21": "Wireless communication for real-time data upload.",
		"rdn51b22": "Self-powered, 24/7 monitoring.",
		"rdn51b23": "Smart IV monitoring for power generation tracking.",
		"rdn51b24": "Precise meteorological data analysis.",
		"rdn51b25": "",
		"rdn51b26": "",
		"rdn31": "Handheld Cleaning Tool",
		"rdn31b11": "Stable Cleaning Head Operation",
		"rdn31b12": "Modular Design",
		"rdn31b13": "Lightweight Design",
		"rdn31b14": "Wet Cleaning for Heavy Dirt",
		"rdn31b15": "Unstuck Button",
		"rdn31b16": "Night Vision",
		"rdn31b21": "Hidden motor design.",
		"rdn31b22": "Easy assembly and disassembly.",
		"rdn31b23": "8kg lightweight design.",
		"rdn31b24": "Wet cleaning for heavy dirt.",
		"rdn31b25": "Unstuck button.",
		"rdn31b26": "Night vision."
	},
	om: {
		mainH1: "Operations & Maintenance Services",
        mainP1: "Green Energy Smart Maintenance",
        c1S1: "Dust Removal",
        c1P1: "Smart cleaning of dust, bird droppings, fallen leaves, and stubborn substances from panels. Focus on cleaning the bottom edge to maintain optimal power generation.",
        c2S1: "Electrical Equipment Inspection",
        c2P1: "Drone, EL, IV, and thermal imaging inspections for panels. Detect cracks, hotspots, cell damage early to enhance efficiency.",
        c2P11: "Inspect inverters, combiner boxes, and other electrical equipment. Eliminate failure risks and ensure safe operation.",
        c2P12: "Optimize the system configuration to improve efficiency by addressing shading and system design.",
        c1S2: "Weed Removal",
        c1P2: "Smart devices remove weeds in spring and autumn to prevent shading. Prevent fire hazards from dried grass in autumn and winter.",
        c2S2: "Snow Removal",
        c2P2: "Quickly remove snow from panels with equipment and manpower at the onset of snowfall. Prevent snow freezing and power loss."
	},
	cases: {
		mainH1: "Case",
		mainP1: "High Efficiency & Low Cost",
		play: "Play",
		rdn71: "Smart Track Cleaning Robot",
		rdn71S1: "Cleaning Site of Xinjiang Solar Power Plant",
		rdn71S2: "Cleaning Site of Ningxia Solar Power Plant",
		rdn22: "Remote Control Cleaning Robot",
		rdn22S1: "Cleaning Site of Roof Solar Power Plant by RDE",
		rdn22S2: "Roof Solar Power Plant Cleaning Site",
		rdn2112: "Smart Cleaning Robot",
		rdn2112S1: "Cleaning Site of Roof Solar Power Plant in Nantong",
		rdn2112S2: "Cleaning Site of Roof Solar Power Plant by RDE",
		rdn51: "Smart Observation Station",
		rdn51S1: "Cleaning Site of Xinjiang Solar Power Plant",
		rdn31: "Handheld Cleaning Tool",
		rdn31S1: "Cleaning Site of Japanese Solar Power Plant",
		rdn31S2: "Roof Solar Power Plant Cleaning Site in Kunshan"
	},
    contact: {
		mainT: "Contact Us",
		mainP1: "ocusing on the operation and maintenance of large-scale PV projects such as power stations, ",
		mainP2: "commercial solar systems, and the innovation in PV products, accessories, and instruments. ",
		mainP3: "The group also has a strong history with metal works and precision processing.",
		companyCNH: "Suzhou Radiant Photovoltaic Technology Co., Ltd.",
		companyCNAddr: "Address:No.636 Zixu Road,Xukou Town,Suzhou, China 215101",
		companyCNTel1: "Telephone：",
		companyCNTel2: "86-512-66572916",
		companyCNFax1: "Fax：",
		companyCNFax2: "86-512-66572756",
		companyCNEml1: "Mailbox：",
		companyCNWz1: "Website：",
		companyCNWz2: "www.radiantpv.com",
		companyCNXs1: "Sales Hotline：",
		companyCNXs2: "0512-66368219",	
		companyCNXs3: "0512-66316900",
		companyCNGo: "Go here",
		companyCNF: "Radiant China",
		companyJAH: "Radiant Japan",
		companyJAAddr: "Address:〒150-0011 東京都渋谷区東三丁目15−5　AYビル4階",
		companyJATel1: "Telephone：",
		companyJATel2: "(03)6427-7173",
		companyJAFax1: "Fax：",
		companyJAFax2: "(03)6427-7183",
		companyJAEml1: "Mailbox：",
		companyJAWz1: "Website：",
		companyJAWz2: "www.radiantpv.com",
		companyJAXs1: "Sales Hotline：",
		companyJAXs2: "0512-66368219",	
		companyJAXs3: "0512-66316900",
		companyJAGo: "Go here",
		companyJAF: "Radiant Japan",
		companyAUH: "Radiant Australia Pty Ltd.",
		companyAUAddr: "Address: 43 Porter Street, Hemmant QLD 4174.",
		companyAUTel1: "Telephone：",
		companyAUTel2: "07 3823 2096",
		companyAUFax1: "Fax：",
		companyAUFax2: "86-512-66572756",
		companyAUEml1: "Mailbox：",
		companyAUWz1: "Website：",
		companyAUWz2: "www.radiantpv.com",
		companyAUXs1: "Sales Hotline：",
		companyAUXs2: "0512-66368219",	
		companyAUXs3: "0512-66316900",
		companyAUGo: "Go here",
		companyAUF: "Radiant Australia"
    }
}