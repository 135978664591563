<template>
  <div ref="aboutRef" class="about">
    <el-row>
      <el-col>
        <div class="jumbotron" :style="{ backgroundImage: `url(${about.bg1Img})` }">
          <div class="txt animate__animated animate__bounceInUp">
            <h1 class="display-4">{{$t('message.about.mainT')}}</h1>
            <p class="lead">{{$t('message.about.mainP1')}}</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div id="company_profile" class="about-row" :style="{ backgroundImage: `url(${about.bg2Img})` }">
          <div class="wrap1500">
            <div class="col">
              <div class="t2">
                <h4>{{$t('message.about.profileH1')}}</h4>
                <h3>{{$t('message.about.profileH2')}}</h3>
              </div>
              <div class="con">
                <span style="white-space:normal;">{{$t('message.about.profileS1')}}</span>
                <br style="white-space:normal;"/>
                <span style="white-space:normal;">{{$t('message.about.profileS2')}}</span>
                <br style="white-space:normal;" />
                <span style="white-space:normal;">{{$t('message.about.profileS3')}}</span>
                <br style="white-space:normal;" />
                <span style="white-space:normal;">{{$t('message.about.profileS4')}}</span>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div id="company_history" class="history-row" :style="{ backgroundImage: `url(${about.bg3Img})` }">
          <div class="wrap1500">
            <div class="col">
              <div class="t2">
                <h4>{{$t('message.about.historyH1')}}</h4>
                <h3>{{$t('message.about.historyH2')}}</h3>
              </div>
            </div>
            <el-row>
              <el-col :span="12">
                <el-timeline style="width: 80%; margin: 0 auto;">
                  <el-timeline-item
                      v-for="(activity, index) in activities.slice(0, 8)"
                      :key="index"
                      :type="activity.type"
                      :size="activity.size"
                  >
                    <h6>{{ activity.content }}</h6>
                    <h6>{{ activity.timestamp }}</h6>
                  </el-timeline-item>
                </el-timeline>
              </el-col>
              <el-col :span="12">
                <el-timeline style="width: 80%; margin: 0 auto;">
                  <el-timeline-item
                      v-for="(activity, index) in activities.slice(8, 16)"
                      :key="index"
                      :type="activity.type"
                      :size="activity.size"
                  >
                    <h6>{{ activity.content }}</h6>
                    <h6>{{ activity.timestamp }}</h6>
                  </el-timeline-item>
                </el-timeline>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div id="company_honor" class="honor-row" :style="{ backgroundImage: `url(${about.bg4Img})` }">
          <div class="wrap1500">
            <div class="col">
              <div class="t2">
                <h4>{{$t('message.about.honorH1')}}</h4>
                <h3>{{$t('message.about.honorH2')}}</h3>
              </div>
            </div>
            <div class="honor-wall" :style="{ backgroundImage: `url(${about.bg5Img})` }"></div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "RadiantAbout",
  components: {},
  computed: {
    activities() {
      return [
        { content: this.$t('message.about.historyC1'), timestamp: '2002', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC2'), timestamp: '2009', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC3'), timestamp: '2012', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC4'), timestamp: '2013-02', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC5'), timestamp: '2013-12', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC6'), timestamp: '2014', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC7'), timestamp: '2016-03', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC8'), timestamp: '2017-03', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC9'), timestamp: '2018', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC10'), timestamp: '2022', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC11'), timestamp: '2023-07', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC12'), timestamp: '2023-09', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC13'), timestamp: '2023-10', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC14'), timestamp: '2023-11', size: 'large', type: 'primary' },
        { content: this.$t('message.about.historyC15'), timestamp: '2024-06', size: 'large', type: 'primary' },
      ]
    }
  },
  data() {
    return {
       about: {
         bg1Img: 'https://pvcleaning.radiantpv.com/image/about/bg.jpg',
         bg2Img: 'https://pvcleaning.radiantpv.com/image/about/company.png',
         bg3Img: 'https://pvcleaning.radiantpv.com/image/about/history.jpg',
         bg4Img: 'https://pvcleaning.radiantpv.com/image/about/honor.jpg',
         bg5Img: 'https://pvcleaning.radiantpv.com/image/about/honor_wall.jpg'
       }
    };
  },
  mounted() {
    //this.navigatorUserAgent();
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    navigatorUserAgent() {
      // 获取用户代理字符串
      const userAgent = window.navigator.userAgent;
      if (/Android/i.test(userAgent)) { // 判断是否为Android
        //alert("isAndroid");
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) { // 判断是否为iOS
        //alert("isIOS");
      } else if (/MicroMessenger/i.test(userAgent)) { // 判断是否在微信中打开
        //alert("isWeChat");
      } else if (/Windows|Macintosh|Linux|x86_64/i.test(userAgent)) { // 判断是否为PC端
        //alert("isPC");
      }
    }
  }
};
</script>

<style scoped>
.jumbotron {
  color: white;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin: 0;
}

.jumbotron .txt {
  margin: 250px auto;
}

.about-row {
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat
}

.about-row .col {
  padding: 10% 10% 10% 5%;
  width: 60%;
  float: right;
  position: relative;
  left: 220px;
}

.about-row .t2 h4 {
  color: red;
}

.about-row .con {
  font-size: 16px;
  line-height: 1.8
}

.about-row .con p {
  margin-bottom: 4%
}

.history-row {
  color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.history-row h2,
.history-row h6 {
  color: white;
}

.history-row .col {
  color: white;
  padding: 5% 5% 5% 5%;
  position: relative;
  left: 30px;
}

.honor-row {
  padding: 100px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.honor-row .t2 h3 {
  color: red;
}

.honor-row .t2 h4 {
  color: black;
}

.honor-row .col {
  padding-bottom: 20px;
  text-align: center;
}

.honor-wall {
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width:1080px) {
  .about-row {
    background-position: 70% 50%;
  }

  .about-row .col {
    left: 50px;
  }

}

@media screen and (max-width:760px) {
  .display-4 {
    font-size: 24px;
  }

  .lead {
    font-size: 14px;
  }

  .about-row .t2 h3 {
    font-size: 16px;
  }

  .about-row .t2 h4 {
    font-size: 14px;
  }

  .about-row .con {
    font-size: 12px;
  }

  .about-row {
    background-position: 60% 50%;
  }

  .about-row .col {
    width: 60%;
    left: 30px;
  }

  .history-row .t2 h3 {
    font-size: 16px;
  }

  .history-row .t2 h4 {
    font-size: 14px;
  }

  .history-row h6 {
    font-size: 12px;
  }

  .honor-row .t2 h3 {
    font-size: 16px;
  }

  .honor-row .t2 h4 {
    font-size: 14px;
  }

  .honor-wall {
    height: 200px;
  }
}
</style>
