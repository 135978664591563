<template>
  <div ref="newsRef" class="news">
    <el-row>
      <el-col>
        <div class="slider" :style="{ backgroundImage: `url(${sliders[slidersIndex].img})` }">
          <div class="slider-news-box">
            <h3 class="hd">
              <div class="wrap1500">
                <i class="iconfont icon-huo"></i>
                <span>{{$t('message.news.mainS1')}}</span>
              </div>
            </h3>
            <div class="slider slider-news">
              <div class="item" v-for="(item, index) in sliders" :key="index">
                <div class="wrap1500" v-if="slidersIndex===index">
                  <div class="pad animate__animated animate__bounceInUp">
                    <h6>{{ item.time }}</h6>
                    <h4><a href="javascript:;">{{ item.content }}</a></h4>
                    <div class="con"><p></p></div>
                    <el-button type="danger" round @click="handleResetVideo">{{$t('message.news.more')}}</el-button>
                  </div>
                </div>
              </div>
              <div class="slider-news-dot">
                <div class="wrap1500">
                  <div class="carousel-dots">
                    <span :class="slidersIndex===index?'dot active':'dot'" v-for="(item, index) in sliders" :key="index" :data-index="index" @click="slidersIndex=index">{{index+1}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <el-dialog v-model="isOpen" :title="$t('message.news.play')" :width="isMobile ? '80%' : '60%'" center @close="handleVideoClose" @close-auto-focus="handleVideoClose">
      <video ref="videoRef" class="d-block w-100" autoplay loop>
        <source :src="sliders[slidersIndex].video" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "RadiantNews",
  components: {},
  computed: {
    ...mapState(['isWinChange']),
    sliders() {
      return [
        { time: '2023-05-05', content: this.$t('message.news.slidersV1'), img: 'https://pvcleaning.radiantpv.com/image/news/cctv.png', video: 'https://pvcleaning.radiantpv.com/video/news/av-cctv.mp4' },
        { time: '2023-11-11', content: this.$t('message.news.slidersV2'), img: 'https://pvcleaning.radiantpv.com/image/news/expo.jpg', video: 'https://pvcleaning.radiantpv.com/video/news/av-expo.mp4' }
      ]
    }
  },
  data() {
    return {
      isMobile: false,
      isOpen: false,
      news: {
        bg1Img: 'https://pvcleaning.radiantpv.com/image/news/cctv.png',
        bg2Img: 'https://pvcleaning.radiantpv.com/image/news/expo.png'
      },
      slidersIndex: 0,
    };
  },
  watch:{
    isWinChange(n) {
      this.isMobile = n;
    }
  },
  created() {
    this.isMobile = this.$store.state.isWinChange;
  },
  methods: {
    handleVideoClose() {
      this.isOpen = false;
      const videoElement = this.$refs.videoRef;
      if (videoElement && videoElement.pause) {
        videoElement.pause();
      }
    },
    handleResetVideo() {
      const videoElement = this.$refs.videoRef;
      if (videoElement) {
        videoElement.load();
      }
      this.isOpen = true;
    }
  }
};
</script>

<style scoped>
.slider {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  margin: 0;
}

.slider-news-box {
  position: absolute;
  top: 20%;
  left: 10%;
  clear: both;
  margin-top: 0;
  width: 100%;
  color: red;
}

.slider-news-box .hd {
  padding: 10px 0;
  font-size: 48px;
}

.slider-news-box .icon-huo {
  font-size: 48px;
}

.slider-news-box h4,
.slider-news-box h6 {
  opacity: .58;
  -webkit-transition: opacity .3s;
  transition: opacity .3s
}

.slider-news-box h6 {
  position: relative;
  color: black;
  font-size: 26px;
}

.slider-news-box h4 {
  line-height: 1.3;
  font-size: 38px;
}

.slider-news-box h4 a {
  color: black;
}

.slider-news-box .con {
  margin-bottom: 18%;
  line-height: 2
}

.slider-news-box .con p {
  font-size: 16px
}

.slider-news-box .pad {
  width: 60%
}

.slider-news-box .item {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.slider-news-box .item .pad {
  -webkit-transition: .3s;
  transition: .3s
}

.slider-news-dot {
  width: 100%;
}

.carousel-dots {
  display: flex;
  justify-content: left;
  margin-top: 50px;
}

.dot {
  position: relative;
  height: 40px;
  width: 40px;
  margin: 0 5px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.3s ease-in-out;
}

.dot:hover {
  animation: blink 0.5s infinite;
}

.dot.active {
  background-color: rgba(255, 0, 0, 0.8);
}

@keyframes blink {
  0%, 100% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }
  50% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }
}

@media screen and (max-width:768px) {
  .slider-news-box .hd span, .slider-news-box .icon-huo {
    font-size: 30px;
  }

  .slider-news h4 {
    font-size: 22px;
  }

  .slider-news h6 {
    font-size: 18px;
  }
}
</style>