import { createI18n } from 'vue-i18n';
import enLocale from 'element-plus/es/locale/lang/en';
import jaLocale from 'element-plus/es/locale/lang/ja';
import zhcnLocale from 'element-plus/es/locale/lang/zh-cn';

const messages = {};
const element = { en: enLocale, ja: jaLocale, 'zh-cn': zhcnLocale };
const itemize = { en: [], 'ja': [], 'zh-cn': [] };
const context = require.context('./', true, /\.js$/);
const modules = context.keys().reduce((acc, key) => {
    if (key !== './index.js') {
        acc[key.replace('./', '')] = context(key).default;
    }
    return acc;
}, {});

for (const path in modules) {
    const key = path.match(/(\S+)\/(\S+).js/);
    if (key && key[2] && itemize[key[2]]) {
        itemize[key[2]].push(modules[path]);
    } else if (key && key[2]) {
        itemize[key[2]] = [modules[path]];
    }
}

function mergeArrObj(list, key) {
    let obj = {};
    (list[key] || []).forEach((i) => {
        obj = Object.assign({}, obj, i);
    });
    return obj;
}

for (const key in itemize) {
    messages[key] = {
        name: key,
        el: element[key].el,
        message: mergeArrObj(itemize, key),
    };
}

export const i18n = createI18n({
    locale: 'zh-cn',
    messages
});