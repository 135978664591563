<template>
  <header ref="headerRef" :class="isMobile ? 'mheader' : 'pheader'">
    <!-- 移动端 -->
    <div class="m_header" v-if="isMobile">
      <div class="m_logo">
        <router-link to="/">
          <img src="@/assets/logo.png">
        </router-link>
      </div>
      <el-row class="block-col-2" style="position:absolute;right:10px;top:0;">
        <el-col :span="8">
          <el-dropdown trigger="click" style="width:80px;height:45px;">
            <span class="el-dropdown-link" style="padding-top:25%;text-align:center;width:100%;height:100%;">
              <el-icon class="el-icon--right"><MoreFilled /></el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="handleToPages('home')">{{$t('message.header.home')}}</el-dropdown-item>
                <el-dropdown-item @click="handleToPages('about')">{{$t('message.header.about')}}</el-dropdown-item>
                <el-dropdown-item @click="handleToPages('news')">{{$t('message.header.news')}}</el-dropdown-item>
                <el-dropdown-item @click="handleToPages('products')">{{$t('message.header.products')}}</el-dropdown-item>
                <el-dropdown-item @click="handleToPages('om')">{{$t('message.header.om')}}</el-dropdown-item>
                <el-dropdown-item @click="handleToPages('cases')">{{$t('message.header.cases')}}</el-dropdown-item>
                <el-dropdown-item @click="handleToPages('contact')">{{$t('message.header.contact')}}</el-dropdown-item>
                <hr/>
                <el-dropdown-item @click="handleChangeLanguage('zh-cn', 'CN | 中文')">CN | 中文</el-dropdown-item>
                <el-dropdown-item @click="handleChangeLanguage('ja', 'JA | 日文')">JA | 日文</el-dropdown-item>
                <el-dropdown-item @click="handleChangeLanguage('en', 'EN | 英文')">EN | 英文</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
      </el-row>
    </div>

    <!-- PC端 -->
    <div class="p-header" v-else>
      <div class="wrap1500">
        <router-link to="/" class="logo">
          <img src="@/assets/logo.png">
        </router-link>
        <div class="language">
          <div class="btn-group" role="group">
            <button
                type="button"
                class="btn bg-danger dropdown-toggle text-white"
                data-toggle="dropdown"
                aria-expanded="false"
            >
              {{ currentLanguage }}
            </button>
            <div class="dropdown-menu">
              <a class="dropdown-item" @click="handleChangeLanguage('zh-cn', 'CN | 中文')">CN | 中文</a>
              <a class="dropdown-item" @click="handleChangeLanguage('ja', 'JA | 日文')">JA | 日文</a>
              <a class="dropdown-item" @click="handleChangeLanguage('en', 'EN | 英文')">EN | 英文</a>
            </div>
          </div>
        </div>
        <ul class="nav">
          <li><router-link to="/">{{$t('message.header.home')}}</router-link></li>
          <li><router-link to="/about">{{$t('message.header.about')}}</router-link></li>
          <li><router-link to="/news">{{$t('message.header.news')}}</router-link></li>
          <li><router-link to="/products">{{$t('message.header.products')}}</router-link></li>
          <li><router-link to="/om">{{$t('message.header.om')}}</router-link></li>
          <li><router-link to="/cases">{{$t('message.header.cases')}}</router-link></li>
          <li><router-link to="/contact">{{$t('message.header.contact')}}</router-link></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'RadintNavigation',
  data() {
    return {
      isMobile: false,
      currentLanguage: "CN | 中文"
    }
  },
  computed: {
    ...mapState(['isWinChange'])
  },
  watch:{
    isWinChange(n) {
      this.isMobile = n;
    }
  },
  methods: {
    handleToPages(pageName) {
      this.$router.push({ name: pageName});
    },
    handleChangeLanguage(l, c) {
      this.$i18n.locale = l;
      this.currentLanguage = c;
    },
    handleInitLanguage() {
      const locale = this.$i18n.locale;
      if ( locale == "zh-cn" ) {
        this.currentLanguage = "CN | 中文";
      } else if ( locale == "ja" ) {
        this.currentLanguage = "JA | 日文";
      } else if ( locale == "en" ) {
        this.currentLanguage = "EN | 英文";
      }
    },
  },
  created() {
    this.handleInitLanguage();
  },
  mounted() {
    this.isMobile = this.$store.state.isWinChange;
  },
  beforeUnmount() {}
};
</script>

<style scoped>
.pheader {
  height: 100px;
}

.mheader {
  height: 50px;
}
</style>