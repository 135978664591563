<template>
  <div class="wrap">
    <div
        v-for="(item, index) in items"
        :key="index"
        :class="`pic${index + 1} pic`"
        :style="{ background: `url(${item.caseImg})` }"
        @mouseenter="handleMouseEnter(index + 1)"
        @click="changeImage(index + 1)"
    >
      <div
          :class="`pic_num${index + 1} pic_num`"
          :style="{ background: `url(${item.numImg})`, backgroundPosition: `-${index*69}px 0px` }"
      >
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'ImgCarousel',
  props: ["items"],
  data() {
    return {
      edgeDistance: 91,
      imgNow: 0,
      imgMouse: 0,
      timeId: null
    };
  },
  mounted() {
    this.startAutoSlide();
  },
  methods: {
    startAutoSlide() {
      this.timeId = setInterval(this.autoSlide, 3000);
    },
    stopAutoSlide() {
      clearInterval(this.timeId);
    },
    autoSlide() {
      if (this.imgNow === 5) {
        this.imgNow = 0;
      } else {
        this.imgNow++;
      }
      this.slideTo(this.imgNow);
    },
    changeImage(index) {
      this.imgNow = index;
      this.slideTo(index);
    },
    slideTo(index) {
      var imgOuter = $('.wrap');
      var imgDiv = $('.pic');
      var imgOuterWidth = imgOuter.width();
      if (index === 0) {
        imgDiv.eq(index).children().hide().siblings().children().show();
        imgDiv.eq(index).siblings().children().show();
        for (var i = imgDiv.length - 1; i > 0; i--) {
          imgDiv.eq(i).animate({'left': imgOuterWidth - (imgDiv.length - i) * this.edgeDistance + 'px'}, 700);
        }
      } else {
        imgDiv.eq(index).children().hide().siblings().children().show();
        imgDiv.eq(index).siblings().children().show();
        imgDiv.eq(index).animate({'left': this.edgeDistance * index + 'px'}, 700);
      }
    },
    handleMouseEnter(index) {
      clearInterval(this.timeId); // 鼠标移入停止自动轮换
      this.imgMouse = index;
      if (this.imgMouse !== this.imgNow) {
        this.mouseSlide();
      }
    },
    mouseSlide() {
      var imgOuter = $('.wrap');
      var imgDiv = $('.pic');
      var imgOuterWidth = imgOuter.width();
      if (this.imgMouse > this.imgNow) {
        for (var i = this.imgNow + 1; i <= this.imgMouse; i++) {
          imgDiv.eq(this.imgMouse).children().hide();
          imgDiv.eq(this.imgMouse).siblings().children().show();
          imgDiv.eq(i).stop().animate({'left': this.edgeDistance * i + 'px'}, 700);
        }
        this.imgNow = this.imgMouse;
      } else {
        for (var j = this.imgNow; j > this.imgMouse; j--) {
          imgDiv.eq(this.imgMouse).children().hide();
          imgDiv.eq(this.imgMouse).siblings().children().show();
          imgDiv.eq(j).stop().animate({'left': imgOuterWidth - (imgDiv.length - j) * this.edgeDistance + 'px'}, 700);
        }
        this.imgNow = this.imgMouse;
      }
      this.startAutoSlide(); // 鼠标移出恢复自动轮换
    }
  },
  beforeUnmount() {
    this.stopAutoSlide();
  }
};
</script>

<style scoped>
.wrap {
  margin: 0px auto;
  width: 1134px;
  height: 437px;
  position: relative;
  overflow: hidden;
}

.pic {
  width: 679px;
  height: 437px;
  cursor: pointer;
}

.pic1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.pic_num1 {
  width: 74px;
  height: 49px;
  position: absolute;
  top: 40px;
  left: 17px;
  display: none;
}

.pic2 {
  position: absolute;
  top: 0;
  left: 679px;
  z-index: 3;
}

.pic_num2 {
  width: 74px;
  height: 49px;
  position: absolute;
  top: 40px;
  left: 17px;
}

.pic3 {
  position: absolute;
  top: 0;
  left: 770px;
  z-index: 5;
}

.pic_num3 {
  width: 74px;
  height: 49px;
  position: absolute;
  top: 40px;
  left: 17px;
}

.pic4 {
  position: absolute;
  top: 0;
  left: 861px;
  z-index: 7;
}

.pic_num4 {
  width: 74px;
  height: 49px;
  position: absolute;
  top: 40px;
  left: 17px;
}

.pic5 {
  position: absolute;
  top: 0;
  left: 952px;
  z-index: 9;
}

.pic_num5 {
  width: 73px;
  height: 49px;
  position: absolute;
  top: 40px;
  left: 18px;
}

.pic6 {
  position: absolute;
  top: 0;
  left: 1043px;
  z-index: 11;
}

.pic_num6 {
  width: 73px;
  height: 49px;
  position: absolute;
  top: 40px;
  left: 18px;
}
</style>