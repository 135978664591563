<template>
  <div ref="omRef" class="om">
    <el-row class="om-main">
      <el-col>
        <div class="jumbotron" :style="{ backgroundImage: `url(${om.bg1Img})` }">
          <div class="txt animate__animated animate__bounceInUp">
            <h1 class="display-4">{{$t('message.om.mainH1')}}</h1>
            <p class="lead">{{$t('message.om.mainP1')}}</p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="rdn-row">
      <el-col
          v-for="(item, index) in omList"
          :key="index"
          :span="24"
      >
        <div class="rdn-card">
          <el-card>
            <img :src="item.img" class="image"/>
            <div class="content">
              <div class="c1">
                <b>{{ item.c1S1 }}</b>
                <div class="bottom">
                  <p>{{ item.c1P1 }}</p>
                </div>
              </div>
              <div class="c2">
                <b>{{ item.c2S1 }}</b>
                <div class="bottom">
                  <p>
                    {{ item.c2P1 }}
                    <br/>
                    {{ item.c2P11 }}
                    <br/>
                    {{ item.c2P12 }}
                  </p>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "RadiantOM",
  components: {},
  computed: {
    omList() {
      return [
        { img: 'https://pvcleaning.radiantpv.com/image/om/chuxue.jpg', c1S1: this.$t('message.om.c1S1'), c1P1: this.$t('message.om.c1P1'), c2S1: this.$t('message.om.c2S1'), c2P1: this.$t('message.om.c2P1'), c2P11: this.$t('message.om.c2P11'), c2P12: this.$t('message.om.c2P12') },
        { img: 'https://pvcleaning.radiantpv.com/image/om/jiance.jpg', c1S1: this.$t('message.om.c1S2'), c1P1: this.$t('message.om.c1P2'), c2S1: this.$t('message.om.c2S2'), c2P1: this.$t('message.om.c2P2'), c2P11: this.$t('message.om.c2P21'), c2P12: this.$t('message.om.c2P22') }
      ]
    }
  },
  data() {
    return {
      om: {
        bg1Img: 'https://pvcleaning.radiantpv.com/image/om/bg.jpg'
      }
    };
  },
  methods: {

  }
};
</script>

<style scoped>
.jumbotron {
  color: white;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin: 0;
}

.jumbotron .txt {
  margin: 250px auto;
}

.rdn-row {
  padding: 100px 0;
  width: 80%;
  margin: 0 auto;
}

.rdn-row .col {
  padding-bottom: 20px;
  text-align: center;
}

.rdn-row .image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rdn-card {
  height: 100%;
  width: 100%;
  margin: 50px auto;
}

.content {
  width: 100%;
  height: 250px;
  padding: 14px;
  text-align: center;
}

.content .c1 {
  float: left;
  width: 50%;
}

.content .c2 {
  float: left;
  width: 50%;
}

.content .bottom {
  width: 85%;
  text-align: center;
  margin: 0 auto;
}

@media screen and (max-width:768px) {
  .display-4 {
    font-size: 24px;
  }

  .lead {
    font-size: 14px;
  }

  .content b, .content p {
    font-size: 12px;
  }
}
</style>