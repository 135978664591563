<template>
  <div ref="downloadsRef" class="downloads">
    <el-row class="downloads-main">
      <el-col>
        <div class="jumbotron" :style="{ backgroundImage: `url(${downloads.bg1Img})` }">
          <div class="txt animate__animated animate__bounceInUp">
            <h1 class="display-4">下载中心</h1>
            <p class="lead"></p>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="rdn-row">
      <el-col>
        <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  瑞得恩应用下载
                </button>
              </h2>
            </div>

            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body">
                <code>
                  <div style="float:left;width:230px;">
                    <img style="width:210px;" src="@/assets/image/downloads/android_code.png"/>
                    <div style="width:210px;font-weight: bold;display:table-cell;text-align: center">RDN+（Android）</div>
                  </div>
                  <div style="float:left;width:230px;">
                    <img style="width:210px;" src="@/assets/image/downloads/apple_code.png"/>
                    <div style="width:210px;font-weight: bold;display:table-cell;text-align: center">RDN+（Apple）</div>
                  </div>
                </code>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  智能轨道机器人
                </button>
              </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div class="card-body">

              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  智能清扫机器人
                </button>
              </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div class="card-body">

              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "RadiantDownloads",
  components: {},
  computed: {},
  data() {
    return {
      downloads: {
        bg1Img: require('@/assets/image/downloads/bg.png')
      }
    };
  },
  methods: {

  }
};
</script>

<style scoped>
.jumbotron {
  color: white;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin: 0;
}

.jumbotron .txt {
  margin: 250px auto;
}

.rdn-row {
  padding: 100px 0;
  width: 80%;
  margin: 0 auto;
}

.rdn-row .col {
  padding-bottom: 20px;
  text-align: center;
}
</style>